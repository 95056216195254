import * as React from "react";

import {
  StyledForm,
  SubTitle,
  MainTitle,
  Row,
  Column,
  SmallColumn,
  Required,
} from "./FormComponents";
import DateFromTo from "../DateFromTo";
import moment from "moment";
interface BookingFormProps {
  title: string;
  datum?: string;
  datumBis?: string;
}

const BookingForm: React.FC<BookingFormProps> = ({
  title,
  datum,
  datumBis,
}) => {
  return (
    <StyledForm id="form">
      <MainTitle>
        <br />
        Buchungsanfrage
        <br />
        {title}
      </MainTitle>
      <SubTitle>
        <DateFromTo datum={datum} datumBis={datumBis} />
      </SubTitle>
      <form
        action="https://formmail.goeast.ch/codezone/goeastform.cfm"
        method="post"
        acceptCharset="ISO-8859-15"
      >
        <input
          name="recipient"
          type="hidden"
          value={process.env.GATSBY_SITE_FORMRECIPIENT}
        />
        <input name="subject" type="hidden" value={`Ferien ${title}`} />
        <input
          name="redirect"
          type="hidden"
          value={`${process.env.GATSBY_SITE_URL}/form-sent`}
        />
        <input name="required" type="hidden" value="Email" />
        <input name="emailcheck" type="hidden" value="Email" />
        <input
          type="hidden"
          name="emailfehler"
          value={`${process.env.GATSBY_SITE_URL}/form-email`}
        />
        <input name="replyto" type="hidden" value="Email" />
        <input
          name="missing"
          type="hidden"
          value={`${process.env.GATSBY_SITE_URL}/form-missing`}
        />
        <input
          type="hidden"
          name="Formular"
          maxLength={255}
          value={`Ferien ${title}`}
        />
        <input
          type="hidden"
          name="Reisedatum"
          value={moment(datum).format("DD.MM.YYYY")}
        />
        <Row>
          <SmallColumn>
            <Required>Anzahl Personen</Required>
          </SmallColumn>
          <Column>
            <Row>
              <SmallColumn>
                <input
                  type="text"
                  name="Anzahl_Personen"
                  maxLength={2}
                  required
                />
              </SmallColumn>
            </Row>
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Zimmer</Required>
          </SmallColumn>
          <Column>
            <Row>
              <Column>
                <Row>
                  <input
                    name="Zimmer_DZEZ"
                    type="radio"
                    id="DZEZ_2"
                    value="EZ"
                    defaultChecked
                  />
                  <label htmlFor="DZEZ_2">EZ</label>
                </Row>
              </Column>
              <Column>
                <Row>
                  <input
                    type="radio"
                    name="Zimmer_DZEZ"
                    value="DZ"
                    id="DZEZ_1"
                  />
                  <label htmlFor="DZEZ_1">DZ</label>
                </Row>
              </Column>
            </Row>
            <Row>
              <SmallColumn>
                <label htmlFor="Zimmer_Anzahl">Anzahl</label>
              </SmallColumn>
              <SmallColumn>
                <input
                  type="text"
                  name="Zimmer_Anzahl"
                  id="Zimmer_Anzahl"
                  maxLength={2}
                  required
                />
              </SmallColumn>
            </Row>
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Annullations&shy;versicherung</Required>
          </SmallColumn>
          <Column>
            <Row>
              <Row>
                <input
                  type="radio"
                  name="Annullationsversicherung"
                  id="av_1"
                  value="Vorhanden"
                  defaultChecked
                />
                <label htmlFor="av_1">Ich habe eine eigene.</label>
              </Row>
              <Row>
                <input
                  type="radio"
                  name="Annullationsversicherung"
                  id="av_2"
                  value="Vorhanden"
                />
                <label htmlFor="av_2">
                  Ich benötige eine Annullationsversicherung
                  <br /> von Ramsauer Carreisen.
                </label>
              </Row>
            </Row>
          </Column>
        </Row>
        <Row>
          <SmallColumn>Firma</SmallColumn>
          <Column>
            <input type="text" name="Firma" />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Name</Required>
          </SmallColumn>
          <Column>
            <input type="text" name="Name" maxLength={255} required />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Vorname</Required>
          </SmallColumn>
          <Column>
            <input type="text" name="Vorname" maxLength={255} required />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Strasse, Nr.</Required>
          </SmallColumn>
          <Column>
            <Row>
              <Column>
                <input type="text" name="Strasse" maxLength={255} required />
              </Column>
              <SmallColumn>
                <input type="text" name="Nr" />
              </SmallColumn>
            </Row>
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>PLZ, Ort</Required>
          </SmallColumn>
          <Column>
            <Row>
              <SmallColumn>
                <input type="text" name="PLZ" required />
              </SmallColumn>
              <Column>
                <input type="text" name="Wohnort" maxLength={255} required />
              </Column>
            </Row>
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Telefon</Required>
          </SmallColumn>
          <Column>
            <input type="text" name="Tel" maxLength={255} required />
          </Column>
        </Row>
        <Row>
          <SmallColumn>Mobil</SmallColumn>
          <Column>
            <input type="text" name="Mobil" maxLength={255} />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>E-Mail</Required>
          </SmallColumn>
          <Column>
            <input type="email" name="Email" maxLength={255} required />
          </Column>
        </Row>
        <Row>
          <SmallColumn>Bemerkungen</SmallColumn>
          <Column>
            <textarea name="Bemerkungen"></textarea>
          </Column>
        </Row>
        <Row>
          <input
            type="submit"
            name="submit_buchungsanfrage"
            value="Anfrage senden"
          />
        </Row>
      </form>
    </StyledForm>
  );
};

export default BookingForm;
