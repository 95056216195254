import * as React from "react";
import Swiper from "react-id-swiper";
import SliderImg from "./SliderImg";
import styled from "styled-components";
import SliderOptions from "./SliderOptions";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
SwiperCore.use([Pagination, Navigation, Autoplay]);

// Workaround see https://github.com/goblindegook/littlefoot/issues/338
require("swiper/swiper-bundle.min.css");

const StyledMainSliderPage = styled.div`
  position: relative;
  max-height: 700px;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  z-index: 6;

  @media print {
    display: none;
  }
`;

const SlideWrapper = styled.div`
  max-height: 700px;
`;

const sizes = "";
const imgixParams = { fit: "crop", ar: "2.743:1", q: 50 };

interface MainSliderProps {
  gallery: [];
}

const MainSlider: React.FC<MainSliderProps> = ({ gallery }) => {
  return (
    <StyledMainSliderPage>
      {gallery.length > 1 ? (
        <Swiper {...SliderOptions}>
          {gallery.map(({ id, url, title, copyright }) => (
            <SlideWrapper key={id}>
              <SliderImg
                src={url}
                sizes={sizes}
                imgixParams={imgixParams}
                title={title}
                copyright={copyright}
              />
            </SlideWrapper>
          ))}
        </Swiper>
      ) : (
        <SliderImg
          src={gallery[0].url}
          sizes={sizes}
          imgixParams={imgixParams}
          title={gallery[0].title}
          copyright={gallery[0].copyright}
        />
      )}
    </StyledMainSliderPage>
  );
};

export default MainSlider;
