import * as React from "react";

import {
  StyledForm,
  MainTitle,
  Title,
  Row,
  Column,
  SmallColumn,
  Required,
} from "./FormComponents";

interface BookingFormProps {
  title: string;
}

const BookingForm: React.FC<BookingFormProps> = ({ title }) => {
  return (
    <StyledForm id="form">
      <MainTitle>
        <br />
        Buchungsanfrage
        <br />
        {title}
      </MainTitle>

      <form
        action="https://formmail.goeast.ch/codezone/goeastform.cfm"
        method="post"
        acceptCharset="ISO-8859-15"
      >
        <input
          name="recipient"
          type="hidden"
          value={process.env.GATSBY_SITE_FORMRECIPIENT}
        />
        <input name="subject" type="hidden" value={`Fahrdienst ${title}`} />
        <input
          name="redirect"
          type="hidden"
          value={`${process.env.GATSBY_SITE_URL}/form-sent`}
        />
        <input name="required" type="hidden" value="Auftraggeber_Email" />
        <input name="emailcheck" type="hidden" value="Auftraggeber_Email" />
        <input
          type="hidden"
          name="emailfehler"
          value={`${process.env.GATSBY_SITE_URL}/form-email`}
        />
        <input name="replyto" type="hidden" value="Auftraggeber_Email" />
        <input
          name="missing"
          type="hidden"
          value={`${process.env.GATSBY_SITE_URL}/form-missing`}
        />
        <input
          type="hidden"
          name="Formular"
          maxLength={255}
          value={`Fahrdienst ${title}`}
        />
        <Title>Auftraggeber</Title>
        <Row>
          <SmallColumn>
            <Required>Name / Institution</Required>
          </SmallColumn>
          <Column>
            <input
              type={"text"}
              name="Auftraggeber_Name"
              maxLength={255}
              required
            />
          </Column>
        </Row>
        <Row>
          <SmallColumn>Zusatz</SmallColumn>
          <Column>
            <input type={"text"} name="Auftraggeber_Zusatz" maxLength={255} />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Telefon</Required>
          </SmallColumn>
          <Column>
            <input
              type={"text"}
              name="Auftraggeber_Telefon"
              maxLength={255}
              required
            />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Strasse</Required>
          </SmallColumn>
          <Column>
            <input
              type={"text"}
              name="Auftraggeber_Strasse"
              maxLength={255}
              required
            />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>PLZ, Ort</Required>
          </SmallColumn>
          <Column>
            <Row>
              <SmallColumn>
                <input type={"text"} name="Auftraggeber_PLZ" required />
              </SmallColumn>
              <Column>
                <input
                  type={"text"}
                  name="Auftraggeber_Ort"
                  maxLength={255}
                  required
                />
              </Column>
            </Row>
          </Column>
        </Row>
        <Row>
          <SmallColumn>Kontaktperson</SmallColumn>
          <Column>
            <input
              type={"text"}
              name="Auftraggeber_Kontaktperson"
              maxLength={255}
            />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>E-Mail</Required>
          </SmallColumn>
          <Column>
            <input
              type={"email"}
              name="Auftraggeber_Email"
              maxLength={255}
              required
            />
          </Column>
        </Row>
        <Row>
          <SmallColumn>Abteilung</SmallColumn>
          <Column>
            <input
              type={"text"}
              name="Auftraggeber_Abteilung"
              maxLength={255}
            />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Rechnungsadresse</Required>
          </SmallColumn>
          <Column>
            <select name="Rechnungsadresse" id="select-rechnungsadresse">
              <option value="Auftraggeber">Auftraggeber</option>
              <option value="Patient">Patient</option>
              <option value="Andere Adresse">Andere Adresse</option>
            </select>
          </Column>
        </Row>
        <Row>
          <SmallColumn>Strasse, Nr.</SmallColumn>
          <Column>
            <Row>
              <Column>
                <input
                  type={"text"}
                  name="Rechnungsadresse_Strasse"
                  id="Rechnungsadresse-Strasse"
                  maxLength={255}
                />
              </Column>
              <SmallColumn>
                <input
                  type={"text"}
                  name="Rechnungsadresse_Nummer"
                  id="Rechnungsadresse-Nummer"
                  maxLength={255}
                />
              </SmallColumn>
            </Row>
          </Column>
        </Row>
        <Row>
          <SmallColumn>PLZ, Ort</SmallColumn>
          <Column>
            <Row>
              <SmallColumn>
                <input
                  type={"text"}
                  name="Rechnungsadresse_PLZ"
                  id="Rechnungsadresse-PLZ"
                />
              </SmallColumn>
              <Column>
                <input
                  type={"text"}
                  name="Rechnungsadresse_Ort"
                  id="Rechnungsadresse-Ort"
                  maxLength={255}
                />
              </Column>
            </Row>
          </Column>
        </Row>
        <Title>Patientenangaben</Title>
        <Row>
          <SmallColumn>
            <Required>Patienten-/Fallnummer</Required>
          </SmallColumn>
          <Column>
            <input
              type={"text"}
              name="Patienten_Fallnummer"
              maxLength={255}
              required
            />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Name/Vorname</Required>
          </SmallColumn>
          <Column>
            <input
              type={"text"}
              name="Patient_Name_Vorname"
              maxLength={255}
              required
            />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Geburtsdatum</Required>
          </SmallColumn>
          <Column>
            <input
              type="date"
              name="Patient_Geburtsdatum"
              maxLength={255}
              id="PatientGeburtsdatum"
              required
            />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Station/Zimmer</Required>
          </SmallColumn>
          <Column>
            <input
              type={"text"}
              name="Patient_Station_Zimmer"
              maxLength={255}
              required
            />
          </Column>
        </Row>
        <Row>
          <SmallColumn>Speziell beachten</SmallColumn>
          <Column>
            <input
              type={"text"}
              name="Patient_Speziell_beachten"
              maxLength={255}
            />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Patient im Rollstuhl</Required>
          </SmallColumn>
          <Column>
            <Row>
              <Column>
                <Row>
                  <input
                    type="radio"
                    name="Patient_im_Rollstuhl"
                    value="JA"
                    id="Patient_im_Rollstuhl_JA"
                  />
                  <label htmlFor="Patient_im_Rollstuhl_JA"> JA</label>
                </Row>
              </Column>
              <Column>
                <Row>
                  <input
                    type="radio"
                    name="Patient_im_Rollstuhl"
                    value="NEIN"
                    id="Patient_im_Rollstuhl_NEIN"
                    defaultChecked
                  />
                  <label htmlFor="Patient_im_Rollstuhl_NEIN"> NEIN</label>
                </Row>
              </Column>
            </Row>
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Wird begleitet</Required>
          </SmallColumn>
          <Column>
            <Row>
              <Column>
                <Row>
                  <input
                    type="radio"
                    name="Patient_Wird_begleitet"
                    value="JA"
                    id="Patient_Wird_begleitet_JA"
                  />
                  <label htmlFor="Patient_Wird_begleitet_JA"> JA</label>
                </Row>
              </Column>
              <Column>
                <Row>
                  <input
                    type="radio"
                    name="Patient_Wird_begleitet"
                    value="NEIN"
                    id="Patient_Wird_begleitet_NEIN"
                    defaultChecked
                  />
                  <label htmlFor="Patient_Wird_begleitet_NEIN"> NEIN</label>
                </Row>
              </Column>
            </Row>
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Liegendtransport</Required>
          </SmallColumn>
          <Column>
            <Row>
              <Column>
                <Row>
                  <input
                    type="radio"
                    name="Patient_Liegendtransport"
                    value="JA"
                    id="Patient_Liegendtransport_JA"
                  />
                  <label htmlFor="Patient_Liegendtransport_JA"> JA</label>
                </Row>
              </Column>
              <Column>
                <Row>
                  <input
                    type="radio"
                    name="Patient_Liegendtransport"
                    value="NEIN"
                    id="Patient_Liegendtransport_NEIN"
                    defaultChecked
                  />
                  <label htmlFor="Patient_Liegendtransport_NEIN"> NEIN</label>
                </Row>
              </Column>
            </Row>
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Patient &gt; 90kg</Required>
          </SmallColumn>
          <Column>
            <Row>
              <Column>
                <Row>
                  <input
                    type="radio"
                    name="Patient_mehr_als_90_Kilo"
                    value="JA"
                    id="Patient_mehr_als_90_Kilo_JA"
                  />
                  <label htmlFor={"Patient_mehr_als_90_Kilo_JA"}> JA</label>
                </Row>
              </Column>
              <Column>
                <Row>
                  <input
                    type="radio"
                    name="Patient_mehr_als_90_Kilo"
                    value="NEIN"
                    id="Patient_mehr_als_90_Kilo_NEIN"
                    defaultChecked
                  />
                  <label htmlFor={"Patient_mehr_als_90_Kilo_NEIN"}> NEIN</label>
                </Row>
              </Column>
            </Row>
          </Column>
        </Row>
        <Row>
          <SmallColumn>mitgegebene Hilfsmittel</SmallColumn>
          <Column>
            <Row>
              <input
                type="text"
                name="Patient_mitgegebene_Hilfsmittel"
                maxLength={255}
              />
            </Row>
            <Row>
              <Column>
                <Row>
                  <input
                    type="checkbox"
                    name="Patient_Sauerstoff"
                    id="Patient_Sauerstoff"
                    value="Sauerstoff"
                    maxLength={255}
                  />
                  <label htmlFor={"Patient_Sauerstoff"}>Sauerstoff</label>
                </Row>
              </Column>
            </Row>
            <Row>
              <Column>
                <Row>
                  <input
                    type="checkbox"
                    name="Patient_Infusion"
                    id="Patient-Infusion"
                    value="Infusion"
                    maxLength={255}
                  />
                  <label htmlFor={"Patient-Infusion"}>Infusion</label>
                </Row>
              </Column>
            </Row>
            <Row>
              <Column>
                <Row>
                  <input
                    type="checkbox"
                    name="Patient_weitere_Hilfsmittel"
                    id="Patient-weitere-Hilfsmittel"
                    value="Weitere Hilfsmittel"
                    maxLength={255}
                  />
                  <label htmlFor={"Patient-weitere-Hilfsmittel"}>
                    Sonstige
                    <br />
                    <br />
                  </label>
                </Row>
                <Row>
                  <input type={"text"} name="Patient_weitere_Hilfmittel_Wert" />
                </Row>
              </Column>
            </Row>
          </Column>
        </Row>
        <Row>
          <Column>
            <Row></Row>
            <Row></Row>
          </Column>
        </Row>
        <Row>
          <SmallColumn>Zusatzinfo</SmallColumn>
          <Column>
            <input type={"text"} name="Patient_Zusatzinfo" maxLength={255} />
          </Column>
        </Row>
        <Title>Transporttermin</Title>
        <Row>
          <Column></Column>
        </Row>
        <Row></Row>
        <Row>
          <SmallColumn>
            <Required>Datum Hinfahrt</Required>
          </SmallColumn>
          <Column>
            <input
              type={"date"}
              name="Transporttermin_Datum_Hinfahrt"
              maxLength={255}
              id="TransportterminDatumHinfahrt"
              required
            />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Abholzeit</Required>
          </SmallColumn>
          <Column>
            <input
              type={"time"}
              name="Transporttermin_Abholzeit"
              maxLength={255}
              id="TransportterminAbholzeit"
              required
            />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Ankunftszeit am Zielort</Required>
          </SmallColumn>
          <Column>
            <input
              type={"time"}
              name="Transporttermin_Ankunftszeit_am_Zielort"
              maxLength={255}
              id="TransportterminAnkunftszeitamZielort"
              required
            />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Abholort (detailierte Adresse)</Required>
          </SmallColumn>
          <Column>
            <textarea name="Transporttermin_Abholort_Adresse" required />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Zielort</Required>
          </SmallColumn>
          <Column>
            <textarea name="Transporttermin_Zielort" required />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Endziel (Abteilung o.ä.)</Required>
          </SmallColumn>
          <Column>
            <input type={"text"} name="Transporttermin_Endziel" required />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Strasse</Required>
          </SmallColumn>
          <Column>
            <input type={"text"} name="Transporttermin_Strasse" required />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>PLZ, Ort</Required>
          </SmallColumn>
          <Column>
            <Row>
              <SmallColumn>
                <input type={"text"} name="Transporttermin_PLZ" required />
              </SmallColumn>
              <Column>
                <input
                  type={"text"}
                  name="Transporttermin_Ort"
                  maxLength={255}
                  required
                />
              </Column>
            </Row>
          </Column>
        </Row>
        <Row>
          <SmallColumn>Kontaktperson am Zielort</SmallColumn>
          <Column>
            <input
              type={"text"}
              name="Transporttermin_Kontaktperson_am_Zielort"
              maxLength={255}
            />
          </Column>
        </Row>
        <Row>
          <SmallColumn>Telefon</SmallColumn>
          <Column>
            <input
              type={"text"}
              name="Transporttermin_Telefon"
              maxLength={255}
            />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Datum Rückfahrt</Required>
          </SmallColumn>
          <Column>
            <input
              type={"date"}
              name="Transporttermin_Datum_Rueckfahrt"
              maxLength={255}
              id="TransportterminDatumRueckfahrt"
            />
          </Column>
        </Row>
        <Row>
          <SmallColumn>
            <Required>Abholzeit</Required>
          </SmallColumn>
          <Column>
            <input
              type={"time"}
              name="Transporttermin_Rueckfahrt_Abholzeit"
              maxLength={255}
              id="TransportterminAbholzeit"
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <input
              type="submit"
              name="submit_buchungsanfrage"
              value="Anfrage senden"
            />
          </Column>
        </Row>
      </form>
    </StyledForm>
  );
};

export default BookingForm;
