import styled from "styled-components";
import { fonts } from "../../styles/variables";

export const Required = styled.span`
  &:after {
    content: " *";
  }
`;
export const MainTitle = styled.h3`
  font-family: ${fonts.helvetica.bold};
  font-size: 28px;
  margin-bottom: 0;
`;
export const SubTitle = styled.h3`
  font-family: ${fonts.helvetica.regular};
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 1.4em;
`;
export const Title = styled.h4`
  font-family: ${fonts.helvetica.bold};
  font-size: 22px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const SmallColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin-left: 7px;
  margin-right: 7px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 3;
  margin-left: 7px;
  margin-right: 7px;
`;

export const StyledForm = styled.div`
  font-size: 18px;
  font-family: ${fonts.helvetica.regular};

  input[type="email"],
  input[type="text"],
  input[type="date"],
  input[type="time"],
  select,
  textarea {
    background-color: rgba(2, 73, 161, 0.2);
    border: none;
    padding: 9px 9px;
    font-size: 18px;
    font-family: ${fonts.helvetica.regular};
    width: 100%;

    &:required {
      background-color: rgba(2, 73, 161, 0.4);
    }
    &:valid {
      background-color: rgba(2, 73, 161, 0.2);
    }
  }

  textarea {
    height: 125px;
  }

  input[type="checkbox"],
  input[type="radio"] {
    margin-top: 13px;
  }

  input[type="submit"] {
    border: none;
    padding: 9px 9px;
    font-size: 18px;
    font-family: ${fonts.helvetica.regular};
    width: 100%;
    color: white;
    background-color: rgba(2, 73, 161, 0.7);
    margin-top: 29px;
    transition: all 0.1s;

    &:hover {
      background-color: rgba(2, 73, 161, 1);
    }
  }

  label {
    padding-top: 7px;
    padding-left: 7px;
  }

  ${Row} {
    margin-top: 11px;
    margin-bottom: 11px;
    ${Row} {
      margin-top: 0;
      margin-bottom: 0;
      ${Row} {
        margin-top: 0;
      }
    }
  }

  ${Column}, ${SmallColumn} {
    :first-of-type {
      margin-left: 0;
    }
    :last-of-type {
      margin-right: 0;
    }
  }
`;
