import * as React from "react";
import { graphql, navigate } from "gatsby";
import { useEffect } from "react";
import Wrapper from "./Wrapper";
import Buchung from "./forms/Buchung";
import Ferien from "./forms/Ferien";
import Fahrdienst from "./forms/Fahrdienst";
import Vermietung from "./forms/Vermietung";
import styled from "styled-components";
import { breakpoints, colors, fonts } from "../styles/variables";
import Helmet from "react-helmet";
import StyledButton from "./StyledButton";
import Page from "./Page";
import DefaultLayout from "../layouts/default";
import MainSliderPage from "../components/MainSliderPage";
import Content from "./Content";

const TitleSection = styled.div`
  margin-top: 50px;

  &.margin-needed {
    margin-top: 100px;
  }
`;
const PreTitle = styled.h2`
  font-size: 18px;
  font-family: ${fonts.helvetica.regular};
  margin-bottom: 0;
`;

const MainTitle = styled.h1`
  font-size: 40px;
  color: ${colors.black};
  font-family: ${fonts.helvetica.regular};
  margin-top: 0;
`;
const ContentWrapper = styled.div`
  font-family: ${fonts.helvetica.regular};
  font-size: 18px;
  line-height: 1.4;
  display: block;

  @media screen and (min-width: ${breakpoints.lg}px) {
    display: flex;
    justify-content: space-between;
  }
`;

const MainContentWrapper = styled.div`
  @media screen and (min-width: ${breakpoints.lg}px) {
    width: calc(55%);
  }
`;
const SidebarContentWrapper = styled.div`
  @media screen and (min-width: ${breakpoints.lg}px) {
    width: calc(40%);
  }
`;

const MainContent = styled(Content)``;
const SidebarContent = styled(Content)``;
const FormContent = styled.div``;

interface ContentPageProps {
  data: {
    cms: {
      entry: {
        bildergalerie?: [];
        title: string;
        mainContent: string;
        sidebarContent: string;
        reisetyp: [];
        showForm: boolean;
        formtype: string;
        datum?: string;
        datumBis?: string;
        metaDescription?: string;
        weiterleitung?: boolean | false;
        children: [];
      };
    };
  };
}

const ContentPage: React.FC<ContentPageProps> = ({
  data: {
    cms: {
      entry: {
        bildergalerie,
        title,
        mainContent,
        sidebarContent,
        reisetyp,
        showForm,
        formtype,
        datum,
        datumBis,
        metaDescription,
        weiterleitung,
      },
    },
  },
}) => {
  weiterleitung &&
    useEffect(() => {
      navigate("/");
    });

  return (
    <DefaultLayout>
      <Helmet
        title={`${title} – ${process.env.GATSBY_SITE_TITLE}`}
        meta={[
          {
            name: "description",
            content: metaDescription,
          },
        ]}
      />
      <Page>
        {bildergalerie !== undefined && bildergalerie.length > 0 ? (
          <MainSliderPage gallery={bildergalerie} />
        ) : (
          ""
        )}
        <Wrapper>
          <TitleSection
            className={
              bildergalerie === undefined ||
              (bildergalerie !== undefined && bildergalerie.length === 0)
                ? "margin-needed"
                : ""
            }
          >
            {reisetyp !== undefined && reisetyp.length > 0 ? (
              <PreTitle>{reisetyp[0].title}</PreTitle>
            ) : (
              ""
            )}
            <MainTitle>{title}</MainTitle>
          </TitleSection>
          <ContentWrapper>
            <MainContentWrapper>
              <MainContent content={mainContent} />
            </MainContentWrapper>
            <SidebarContentWrapper>
              <SidebarContent content={sidebarContent} />
              {showForm && formtype != "" && (
                <a href="#form">
                  <StyledButton buttontext="Buchen" />
                </a>
              )}
            </SidebarContentWrapper>
          </ContentWrapper>
          <MainContentWrapper>
            {/*  Tagesreise: buchung */}
            {/*  Mehrtagesreise: ferien */}
            {/*  Fahrdienst: fahrdienst */}
            {/*  Vermietung: vermietung */}
            {showForm && formtype != "" && (
              <FormContent>
                {formtype == "buchung" && (
                  <Buchung title={title} datum={datum} datumBis={datumBis} />
                )}
                {formtype == "ferien" && (
                  <Ferien title={title} datum={datum} datumBis={datumBis} />
                )}
                {formtype == "fahrdienst" && <Fahrdienst title={title} />}
                {formtype == "vermietung" && <Vermietung title={title} />}
              </FormContent>
            )}
          </MainContentWrapper>
        </Wrapper>
      </Page>
    </DefaultLayout>
  );
};

export default ContentPage;

export const query = graphql`
  query($uri: String!) {
    cms {
      entry(uri: [$uri]) {
        ... on CMS_reiseangebote_reiseangebote_Entry {
          id
          title
          slug
          uri
          datum
          datumBis
          bildergalerie {
            id
            title
            url
            img
            slug
            path
            ... on CMS_assets_Asset {
              copyright
            }
          }
          buttonfarbe
          buttontext
          sidebarContent
          mainContent
          metaDescription
          reisetyp {
            id
            title
            slug
          }
          status
          showInSlider
          showForm
          formtype
        }
        ... on CMS_pages_pages_Entry {
          id
          title
          slug
          uri
          bildergalerie {
            id
            title
            url
            img
            slug
            path
            ... on CMS_assets_Asset {
              copyright
            }
          }
          sidebarContent
          mainContent
          weiterleitung
          metaDescription
          status
          showForm
          formtype
        }
      }
    }
  }
`;
